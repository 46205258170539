import * as React from "react";
import { useRouter } from "next/router";
import Script from "next/script";
import VerticalSpacingContext from "src/lib/context/VerticalSpacingContext";

import { RenderSections } from "../../lib/components";
import useBookingPage from "../../lib/hooks/useBookingPage";
import { QueryData } from "../../lib/queries/server";
import {
  composePaths,
  composeProps,
  PageProps,
} from "../../lib/services/util/compose";
import { isTruthy } from "../../lib/util";
import { filterListByLocales, LocaleCode } from "../../lib/util/locale";

export const getStaticPaths = composePaths(async (context, { queryClient }) => {
  const paths = await queryClient.fetchQuery<QueryData<"pagePaths">>([
    "pagePaths",
  ]);

  const configuredLocales = context.locales as LocaleCode[] | undefined;

  return {
    paths: paths
      .flatMap((path) => {
        const allLocales = Object.keys(path || {});
        const locales = filterListByLocales(allLocales, configuredLocales);
        return locales.map((locale) => {
          // ensure we replace "/" with "home" for redirected home page
          const originalSlug = path[locale];
          const slug = originalSlug === "/" ? "home" : originalSlug;
          if (!slug) return undefined;
          return { params: { slug }, locale };
        });
      })
      .filter(isTruthy),
    fallback: "blocking",
  };
});

export const getStaticProps = composeProps(async (context, { queryClient }) => {
  const pagePath = context.params?.slug === "home" ? "/" : context.params?.slug;
  const data = await queryClient.fetchQuery<QueryData<"pageData">>([
    "pageData",
    pagePath,
  ]);

  if (!data) {
    return {
      notFound: true,
    };
  }
  return {
    props: data,
    // weekly
    revalidate: 604800,
  };
});

export interface ArbitraryPageProps {
  sections: React.ComponentProps<typeof RenderSections>["sections"];
}

export default function ArbitraryPage({
  sections,
}: PageProps<ArbitraryPageProps>) {
  const [isBookingPage, bookingPageUI] = useBookingPage();
  const router = useRouter();

  const spacingOjb = React.useMemo(
    () => ({
      hasVerticalSpacing: !["/"].includes(router.asPath),
    }),
    [router.asPath]
  );

  return (
    <VerticalSpacingContext.Provider value={spacingOjb}>
      {router.asPath === "/" && (
        <Script id="org-schema" type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Corporation",
            "name": "Thuma",
            "legalName": "Thuma Inc.",
            "foundingDate": "2016",
            "description": "Thuma is a modern indoor focused lifestyle brand and the creator of The Bed,
            a modern platform bed that reimagines what a bed frame can be. Using sustainably sourced materials, Japanese joinery, and minimalist style, The Bed, by Thuma is an eco-friendly solution for a better Bed Time. Thuma has additionally, thoughtfully designed bedroom essentials coming soon.",
            "url": "https://www.thuma.co/",
            "logo": "https://cdn.shopify.com/s/files/1/2448/0687/files/logo_180x.png?v=1515487245", "ContactPoint": {
            "contactType": "customer support",
            "email": "help@thuma.co" },
            "address": {
            "@type": "PostalAddress", "addressLocality": "San Francisco", "addressRegion": "CA", "postalCode": "94123", "addressCountry": "USA"
            }, "sameAs": [
            "https://www.facebook.com/thuma.co/", "https://www.instagram.com/thuma.co/", "https://www.pinterest.com/thumaforbedtime/", "https://www.linkedin.com/company/thuma/"]
          }`}
        </Script>
      )}
      <RenderSections sections={sections} />
      {isBookingPage ? bookingPageUI : null}
    </VerticalSpacingContext.Provider>
  );
}
